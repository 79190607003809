<!-- =========================================================================================
  File Name: DataListListView.vue
  Description: Data List - List View
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->
<template>
    <div id="data-list-list-view" class="data-list-container">
      <h1>{{ $t("riskZoneAssessments") }}</h1>
      <br>
      <vs-table ref="table" pagination :max-items="itemsPerPage" search :data="riskZoneAssessments">
  
        <div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-between">
  
          <div class="flex flex-wrap-reverse items-center data-list-btn-container">
            <!-- ADD NEW -->
            <div class="btn-add-new p-3 mb-4 mr-4 rounded-lg cursor-pointer flex items-center justify-center text-lg font-medium text-base text-primary border border-solid border-primary" @click="addNewData">
              <feather-icon icon="PlusIcon" svgClasses="h-4 w-4" />
              <span class="ml-2 text-base text-primary">Ajouter</span>
            </div>
        
          </div>
  
          <!-- ITEMS PER PAGE -->
          <vs-dropdown vs-trigger-click class="cursor-pointer mb-4 mr-4 items-per-page-handler">
            <div class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
              <span class="mr-2">{{ currentPage * itemsPerPage - (itemsPerPage - 1) }} - {{ riskZoneAssessments.length - currentPage * itemsPerPage > 0 ? currentPage * itemsPerPage : riskZoneAssessments.length }} of {{ queriedItems }}</span>
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
            </div>
            <!-- <vs-button class="btn-drop" type="line" color="primary" icon-pack="feather" icon="icon-chevron-down"></vs-button> -->
            <vs-dropdown-menu>
  
              <vs-dropdown-item @click="itemsPerPage=20">
                <span>20</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="itemsPerPage=40">
                <span>40</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="itemsPerPage=60">
                <span>60</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="itemsPerPage=80">
                <span>80</span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
        </div>
  
        <template slot="thead">
          <vs-th sort-key="site">{{ $t("site") }}</vs-th>
          <vs-th sort-key="libelle">{{ $t("libelle") }}</vs-th>
<!--           <vs-th sort-key="sites">{{ $t("sites") }}</vs-th>
 -->      <!--     <vs-th sort-key="questions">{{ $t("questions") }}</vs-th>
          <vs-th sort-key="conditions">{{ $t("conditions") }}</vs-th> -->
          <vs-th>Action</vs-th>
        </template>
  
        <template slot-scope="{data}">
          <tbody>
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td>
              <p class="product-name font-medium truncate">{{ tr.site.name}}</p>
            </vs-td>
            <vs-td>
              <p class="product-name font-medium truncate">{{ tr.name}}</p>
            </vs-td>
            
          <!--   <vs-td>
              <li v-for="questionHasFile in tr.questionHasFiles" :key="questionHasFile" class="product-name font-medium truncate">{{ questionHasFile.question.description}}</li>
            </vs-td>
            <vs-td>
              <li  v-for="questionHasFile in tr.questionHasFiles" :key="questionHasFile" class="product-name font-medium truncate">{{ApprouveObject[questionHasFile.condition]}}</li>
            </vs-td>
            -->
            <vs-td class="whitespace-no-wrap">
              <div class="flex">
               <!--  <vx-tooltip  text="Voir" color="#28C76F">
                  <feather-icon style="color: #28C76F " icon="ImageIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current"   @click="openLightbox" @click.stop="Show_image(tr)" color="warring" type="border" class="mr-2" />
                </vx-tooltip> -->
                <vx-tooltip text="Voir" color="primary">
                  <feather-icon style="color: #0627bb" icon="EyeIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current" class="mr-2" @click="ShowData(tr)"/>
                </vx-tooltip>
                <vx-tooltip text="Modifier" color="warning">
                  <feather-icon style="color: #FF9F43" icon="EditIcon" svgClasses="w-5 h-5 hover:text-warning stroke-current" class="mr-2" @click="editData(tr)"/>
                </vx-tooltip>
  <!--              <vx-tooltip v-if="profile_moov!=='studio'" text="Supprimer" color="danger">-->
                <vx-tooltip text="Supprimer" color="danger">
                  <feather-icon @click="deleteData(tr.id)" style="color: red" icon="TrashIcon" svgClasses="w-5 h-5 hover:text-danger stroke-current" class="mr-2"/>
                </vx-tooltip>
              </div>
            </vs-td>
          </vs-tr>
          </tbody>
          
        </template>
      </vs-table>
      <vs-popup
      v-if="activePrompt1" 
      fullscreen
        class="calendar-event-dialog"
        title="Ajouter l'évaluations de la zone d'exclusion"
        :active.sync="activePrompt1">
        <div class="clearfix">

          <div class="vx-row">
            
            <div class="vx-col sm:w-full md:w-1/2 lg:1/2 xs:w-full">
         
             <p>{{ $t("site") }}<b style="color: #ff6141" >*</b> </p>
                <vs-select
                  :closeOnSelect="false"
                  v-model="site"
                  data-vv-validate-on="blur"
                  v-validate="'required'"
                  autocomplete
                  name="site"
                  class="w-full"
              >
            <vs-select-item :key="item1" :value="item1.id" :text="item1.name" v-for="item1 in sites_list" /></vs-select>
            <span class="text-danger text-sm" v-show="errors.has('site')">{{ errors.first("site") }}</span>
            
            </div>
    
            <div class="vx-col sm:w-full md:w-1/2 lg:1/2 xs:w-full">
            <p>Libelle<b style="color: #ff6141">*</b></p>
              <vs-input
                v-validate="'required'"
                data-vv-validate-on="blur"
                name="name"
                v-model="name"
                class="w-full"
              />
            <span class="text-danger text-sm" v-show="errors.has('name')">{{ errors.first("name") }}</span>
            </div>


          </div>

          <vx-card class="mt-10" no-shadow card-border>
       <div class="vx-row ">
        <div class="vx-col w-full">
          <div class="flex items-end px-3">
            <feather-icon svgClasses="w-6 h-6" icon="FileTextIcon" class="mr-2" />
            <span class="font-medium text-lg leading-none">Questionnaires </span>
          </div>
          <vs-divider />
        </div>
      </div>
  
    <div class="vx-row ">

      <vs-table stripe  class="w-full  "  :data="questions_list">
      <template slot="thead">
        <vs-th>N°</vs-th>
        <vs-th>Questions</vs-th>
        <vs-th></vs-th>
        <vs-th></vs-th>
        <vs-th></vs-th>

      </template>

      <template slot-scope="{data}">
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data" >
        
          <vs-td>{{indextr+1}}</vs-td>
          <vs-td >{{tr.description}}
          </vs-td>
          
          <vs-td >
            <vs-checkbox  v-model="tr.conditionVrai " @change="onOptionChange_Vrai(tr,indextr)">Oui</vs-checkbox>
           </vs-td>
          <vs-td >
            <vs-checkbox  v-model="tr.conditionFaux " @change="onOptionChange_Faux(tr,indextr)">Non</vs-checkbox>
           </vs-td>
          <vs-td >
            <vs-checkbox v-model="tr.conditionNa " @change="onOptionChange_Na(tr,indextr)">NA</vs-checkbox>
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>
    </div>

          </vx-card>
          <vs-button color="success"  class="float-right mt-4" @click="submitData()" >Valider</vs-button>

        </div>
      </vs-popup>
      
      <vs-popup
       v-if="activePrompt2" 
        fullscreen
        class="calendar-event-dialog"
        title="Modifier l'évaluations de la zone d'exclusion"
        :active.sync="activePrompt2">
        <div class="clearfix">
          <div class="vx-row">
            
            <div class="vx-col sm:w-full md:w-1/2 lg:1/2 xs:w-full">
         
             <p>{{ $t("site") }}<b style="color: #ff6141" >*</b> </p>
                <vs-select
                  :closeOnSelect="false"
                  v-model="site"
                  data-vv-validate-on="blur"
                  v-validate="'required'"
                  autocomplete
                  name="site"
                  class="w-full"
              >
            <vs-select-item :key="item1" :value="item1.id" :text="item1.name" v-for="item1 in sites_list" /></vs-select>
            <span class="text-danger text-sm" v-show="errors.has('site')">{{ errors.first("site") }}</span>
            
            </div>
    
            <div class="vx-col sm:w-full md:w-1/2 lg:1/2 xs:w-full">
            <p>Libelle<b style="color: #ff6141">*</b></p>
              <vs-input
                v-validate="'required'"
                data-vv-validate-on="blur"
                name="name"
                v-model="name"
                class="w-full"
              />
            <span class="text-danger text-sm" v-show="errors.has('name')">{{ errors.first("name") }}</span>
            </div>


          </div>

          <vx-card class="mt-10" no-shadow card-border>
       <div class="vx-row ">
        <div class="vx-col w-full">
          <div class="flex items-end px-3">
            <feather-icon svgClasses="w-6 h-6" icon="FileTextIcon" class="mr-2" />
            <span class="font-medium text-lg leading-none">Questionnaires </span>
          </div>
          <vs-divider />
        </div>
      </div>
    
    
      <div class="vx-row ">
      <vs-table stripe class="w-full  " :data="produits_computed">
      <template slot="thead">
        <vs-th>N°</vs-th>
        <vs-th>Questions</vs-th>
        <vs-th></vs-th>
        <vs-th></vs-th>
        <vs-th></vs-th>
       
      </template>

      <template slot-scope="{data}">
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data" >
        
         
          <vs-td>{{indextr+1}}</vs-td>
       
          <vs-td >{{tr.description}}
          </vs-td>
          
          <vs-td >
            <vs-checkbox  v-model="tr.conditionVrai " @change="onOptionChange_Vrai_up(tr,indextr)">Oui</vs-checkbox>
          </vs-td>
          <vs-td >
            <vs-checkbox  v-model="tr.conditionFaux " @change="onOptionChange_Faux_up(tr,indextr)">Non</vs-checkbox>
          </vs-td>
          <vs-td >
            <vs-checkbox v-model="tr.conditionNa " @change="onOptionChange_Na_up(tr,indextr)">NA</vs-checkbox>
         </vs-td>
        </vs-tr>
      </template>
      </vs-table>
    </div>
 
 
          </vx-card>
          <vs-button color="success"  class="float-right mt-4" @click="submitData()" >Valider</vs-button>
        </div>
      </vs-popup>
 
      <vs-popup
      v-if="activePrompt3" 
      fullscreen
        class="calendar-event-dialog"
        title="Détails de l'évaluations de la zone d'exclusion"
        :active.sync="activePrompt3">
        <div class="clearfix">
          <div class="vx-row">
            
            <div class="vx-col sm:w-full md:w-1/2 lg:1/2 xs:w-full">
         
             <p>{{ $t("site") }}<b style="color: #ff6141" >*</b> </p>
                <vs-select
                  disabled
                  :closeOnSelect="false"
                  v-model="site"
                  data-vv-validate-on="blur"
                  v-validate="'required'"
                  autocomplete
                  name="site"
                  class="w-full"
              >
            <vs-select-item :key="item1" :value="item1.id" :text="item1.name" v-for="item1 in sites_list" /></vs-select>
            <span class="text-danger text-sm" v-show="errors.has('site')">{{ errors.first("site") }}</span>
            
            </div>
    
            <div class="vx-col sm:w-full md:w-1/2 lg:1/2 xs:w-full">
            <p>Libelle<b style="color: #ff6141">*</b></p>
              <vs-input
                disabled
                v-validate="'required'"
                data-vv-validate-on="blur"
                name="name"
                v-model="name"
                class="w-full"
              />
            <span class="text-danger text-sm" v-show="errors.has('name')">{{ errors.first("name") }}</span>
            </div>


          </div>

          <vx-card class="mt-10" no-shadow card-border>
       <div class="vx-row ">
        <div class="vx-col w-full">
          <div class="flex items-end px-3">
            <feather-icon svgClasses="w-6 h-6" icon="FileTextIcon" class="mr-2" />
            <span class="font-medium text-lg leading-none">Questionnaires </span>
          </div>
          <vs-divider />
        </div>
      </div>
  
    
    <div class="vx-row ">
      <vs-table stripe class="w-full  " :data="produits_computed">
      <template slot="thead">
        <vs-th>N°</vs-th>
        <vs-th></vs-th>
        <vs-th>Questions</vs-th>
        <vs-th></vs-th>
        <vs-th></vs-th>
        <vs-th></vs-th>
       
      </template>

      <template slot-scope="{data}">
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data" >
        
         
          <vs-td>{{indextr+1}}</vs-td>
          <vs-td>
            <vs-progress v-if="tr.conditionVrai === true" :percent="100" :color="getOrderStatusColor1(tr.colorVrai)"></vs-progress>
            <vs-progress v-if="tr.conditionFaux === true" :percent="100" :color="getOrderStatusColor1(tr.colorFaux)"></vs-progress>
            <vs-progress v-if="tr.conditionNa === true" :percent="100" :color="getOrderStatusColor1(tr.colorNa)"></vs-progress>

          </vs-td>

          <vs-td >{{tr.description}}
          </vs-td>
          
          <vs-td >
            <vs-checkbox disabled v-model="tr.conditionVrai " @change="onOptionChange_Vrai_up(tr,indextr)">Oui</vs-checkbox>
          </vs-td>
          <vs-td >
            <vs-checkbox disabled v-model="tr.conditionFaux " @change="onOptionChange_Faux_up(tr,indextr)">Non</vs-checkbox>
          </vs-td>
          <vs-td >
            <vs-checkbox disabled v-model="tr.conditionNa " @change="onOptionChange_Na_up(tr,indextr)">NA</vs-checkbox>
         </vs-td>
        </vs-tr>
      </template>
      </vs-table>
    </div>
 
          </vx-card>
         
          <vs-button color="primary"  class="float-right mt-4" @click="quit()" >Quitter</vs-button>
        </div>
      </vs-popup>
    </div>
  </template>
<script>

import { Validator } from 'vee-validate'

const dict = {
  custom: {
    name: {
      required: 'Le champ libelle  est obligatoire'
    },
    site: {
      required: 'Le champ site est obligatoire'
    }
   
  }
}
Validator.localize('fr', dict)
export default {
  data () {
    return {

      selectedOption: null,
      sites_list:[],
      questions_list:[],
      sites_tab:'',
      conditions_tab:false,
      
      questions_tab:'',
      // conditions_Questions:[],

      id_produit:1,
      produits_computed:[],
      produit_submit:[],
      id:'',
      id_up:'',
      old_produitID:[],
      repProduit:[],
      itemIndexProduit:-1,
      update_produitChek : false,
      Has_Med_Dos_Qty_update : false,

      activePrompt3:false,
      activePrompt2:false,
      activePrompt1:false,
      Check_update:false,

      site:'',
      riskZoneAssessments: [],
    
      selected: [],
      selected_up:[],
      isMounted: false,
      itemsPerPage: 20,

      ApprouveObject: {
        true: 'Oui',
        false: 'Non'
      },
      show_update : false
    }
  },
  
  computed: {
    currentPage () {
      if (this.isMounted) {
        return this.$refs.table.currentx
      }
      return 0
    },
    queriedItems () {
      return this.$refs.table ? this.$refs.table.queriedResults.length : this.riskZoneAssessments.length
    },
    validate () {
      if (
        (
          this.questions_tab !== ''
        )) return true
    }
  },
 
  methods: {
    onOptionChange_Vrai (tr) {
      this.obj_ques = ''
      if (tr.conditionVrai === true) {
        const obj_ques = {
          id: tr.id,
          description: tr.description,
          colorFaux: tr.colorFaux,
          colorNa: tr.colorNa,
          colorVrai: tr.colorVrai,
          conditionVrai: true,
          conditionFaux: false,
          conditionNa: false

        }
        this.UPDATE_ITEM_PRODUIT(this.questions_list, obj_ques)

      } 
    },
    onOptionChange_Faux (tr) {
      this.obj_ques = ''
      if (tr.conditionFaux === true) {
        const obj_ques = {
          id: tr.id,
          description: tr.description,
          colorFaux: tr.colorFaux,
          colorNa: tr.colorNa,
          colorVrai: tr.colorVrai,
          conditionVrai: false,
          conditionFaux: true,
          conditionNa: false

        }
        this.UPDATE_ITEM_PRODUIT(this.questions_list, obj_ques)

      } 
    },
    onOptionChange_Na (tr) {
      this.obj_ques = ''
      if (tr.conditionNa === true) {
        const obj_ques = {
          id: tr.id,
          description: tr.description,
          colorFaux: tr.colorFaux,
          colorNa: tr.colorNa,
          colorVrai: tr.colorVrai,
          conditionVrai: false,
          conditionFaux: false,
          conditionNa: true

        }
        this.UPDATE_ITEM_PRODUIT(this.questions_list, obj_ques)

      } 
    },

    onOptionChange_Vrai_up (tr) {
      this.obj_ques = ''
      if (tr.conditionVrai === true) {
        const obj_ques = {
          id_obj:tr.id_obj,
          id: tr.id,
          description: tr.description,
          colorFaux: tr.colorFaux,
          colorNa: tr.colorNa,
          colorVrai: tr.colorVrai,
          conditionVrai: true,
          conditionFaux: false,
          conditionNa: false

        }
        this.UPDATE_ITEM_PRODUIT(this.produits_computed, obj_ques)

      } 
    },
    onOptionChange_Faux_up (tr) {
      this.obj_ques = ''
      if (tr.conditionFaux === true) {
        const obj_ques = {
          id_obj:tr.id_obj,
          id: tr.id,
          description: tr.description,
          colorFaux: tr.colorFaux,
          colorNa: tr.colorNa,
          colorVrai: tr.colorVrai,
          conditionVrai: false,
          conditionFaux: true,
          conditionNa: false

        }
        this.UPDATE_ITEM_PRODUIT(this.produits_computed, obj_ques)

      } 
    },
    onOptionChange_Na_up (tr) {
      this.obj_ques = ''
      if (tr.conditionNa === true) {
        const obj_ques = {
          id_obj:tr.id_obj,
          id: tr.id,
          description: tr.description,
          colorFaux: tr.colorFaux,
          colorNa: tr.colorNa,
          colorVrai: tr.colorVrai,
          conditionVrai: false,
          conditionFaux: false,
          conditionNa: true

        }
        this.UPDATE_ITEM_PRODUIT(this.produits_computed, obj_ques)

      } 
    },
    handleSelected_up (tr) {
      this.obj_ques = ''
      if (tr.condition === true) {
        const obj_ques = {
          id_obj:tr.id_obj,
          id: tr.id,
          condition: false,
          question:tr.question,
          colorFaux: tr.colorFaux,
          colorVrai: tr.colorVrai
        }
        this.UPDATE_ITEM_PRODUIT_2(this.produits_computed, obj_ques)

      } else if (tr.condition === false) {
        const obj_ques = {
          id_obj:tr.id_obj,
          id: tr.id,
          condition: true,
          question:tr.question,
          colorFaux: tr.colorFaux,
          colorVrai: tr.colorVrai
          

        }
        this.UPDATE_ITEM_PRODUIT_2(this.produits_computed, obj_ques)
      }

      /*   
      console.log('voir l\'objet ', tr)
      this.$vs.notify({
        title: `Selected ${tr.description}`,
        text: `Email: ${tr.condition}`
      }) */
    },
    handleSelected (tr) {
      this.obj_ques = ''
      if (tr.condition === true) {
        const obj_ques = {
          id: tr.id,
          category:tr.category,
          colorFaux: tr.colorFaux,
          colorVrai: tr.colorVrai,
          condition: false,
          description:tr.description,
          created_at:tr.created_at,
          updated_at:tr.updated_at

        }
        this.UPDATE_ITEM_PRODUIT(this.questions_list, obj_ques)

      } else if (tr.condition === false) {
        const obj_ques = {
          id: tr.id,
          category:tr.category,
          colorFaux: tr.colorFaux,
          colorVrai: tr.colorVrai,
          condition: true,
          description:tr.description,
          created_at:tr.created_at,
          updated_at:tr.updated_at

        }
        this.UPDATE_ITEM_PRODUIT(this.questions_list, obj_ques)
      }

      /*   
      console.log('voir l\'objet ', tr)
      this.$vs.notify({
        title: `Selected ${tr.description}`,
        text: `Email: ${tr.condition}`
      }) */
    },
    getOrderStatusColor1 (status) {
      if (status === 'red')   return '#FF0000'
      if (status === 'yellow') return '#E6FF00'
      if (status === 'white') return '#FFFFFF'
      if (status === 'green') return 'success'
    },
    submit_produit () {
      this.produit_submit.length = 0
      const obj = {
        id: this.id_produit++,
        sites: this.sites_tab,
        conditions: this.conditions_tab,
        questions: this.questions_tab
      }

      this.produit_submit = obj
     
      this.validateFormation_intialise()
      this.FIND_ITEM_PRODUIT(this.produits_computed, this.produit_submit)
      if (this.itemIndexProduit === -1) {
        this.ADD_ITEM_PRODUIT(this.produits_computed, this.produit_submit)
        this.$vs.notify({
          color:'success',
          icon:'done',
          text:'Questionnaire ajoutée à la liste',
          position:'top-right'
        })
      } else if (this.itemIndexProduit !== -1) {
        this.$vs.notify({
          color:'warning',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          time:15000,
          text:'La questionnaire existe déjà dans la liste',
          position:'top-center'
        })
      }
     
    },
   
    validateFormation_intialise () {
      this.sites_tab = ''
      this.conditions_tab = false
      this.questions_tab = ''
      this.update_produitChek = false
    },
    resetData () {
      this.id = ''
      this.sites_tab = ''
      this.conditions_tab = false
      this.questions_tab = ''
      this.update_produitChek = false
    }, 
    deleteProduit (id) {
      this.REMOVE_ITEM_PRODUIT(this.produits_computed, id)
      this.$vs.notify({
        color:'success',
        icon:'delete',
        text:'Questionnaire supprimée de la liste',
        position:'top-right'
      })
    
    },
    update_pro_chek (prod) {
      this.id = prod.id

      if (this.show_update === true) {
        //this.sites_tab = prod.sites.name
        this.conditions_tab = prod.conditions
        this.questions_tab = prod.questions.description
      } else {
        //this.sites_tab = prod.sites
        this.conditions_tab = prod.conditions
        this.questions_tab = prod.questions
      }
     
      this.update_produitChek = true
    },
  
    updateProduit () {
      this.load()
      const obj = {
        id: this.id,
        sites: this.sites_tab,
        conditions: this.conditions_tab,
        questions: this.questions_tab
      }
      this.UPDATE_ITEM_PRODUIT(this.produits_computed, obj)
      this.acceptAlert()
      this.resetData()
    },
    load () {
      this.$vs.loading()
      setTimeout(() => {
        this.$vs.loading.close()
      }, 500)
    },
    acceptAlert () {
      this.$vs.notify({
        color:'success',
        icon:'edit',
        text:'Questionnaire modifiée',
        position:'top-right'
      })
    },
    removeItemAll (arr, value) {
      let i = 0
      while (i < arr.length) {
        if (arr[i] === value) {
          arr.splice(i, 1)
        } else {
          ++i
        }
      }
      return arr
    },
    ADD_ITEM_PRODUIT (produits, produit) {
      produits.unshift(produit)
    },
    REMOVE_ITEM_PRODUIT (produits, produitId) {
      const ItemIndex = produits.findIndex((p) => p.id === produitId)
      produits.splice(ItemIndex, 1)
    },
    UPDATE_ITEM_PRODUIT (produits, produit) {
      const itemIndex = produits.findIndex((p) => p.id === produit.id)
      Object.assign(produits[itemIndex], produit)
    },
    UPDATE_ITEM_PRODUIT_2 (produits, produit) {
      const itemIndex = produits.findIndex((p) => p.id_obj === produit.id_obj)
      Object.assign(produits[itemIndex], produit)
    },
    FIND_ITEM_PRODUIT (produits, produit) {
      this.itemIndexProduit = produits.findIndex((p) => p.questions.id === produit.questions.id)
    },
    addNewData () {
      this.$validator.reset()
      this.intialise()
      this.getAllquestions()
      this.Check_update = false
      this.activePrompt1 = true
    },
    quit () {
      this.activePrompt3 = false
      this.$validator.reset()
      this.intialise()
    },
    ShowData (data) {
      this.intialise()
      this.Has_Med_Dos_Qty_update = true
      this.show_update = true
      this.id_produit =  ''
      this.produits_computed = []
      this.repProduit.length = 0
      this.name = data.name
      this.site = data.site.id
       
      for (let i = 0; i < data.questionHasFiles.length; i++) {
        const obj = {
          id_obj:'',
          id: '',
          description: '',
          colorFaux: '',
          colorNa: '',
          colorVrai: '',
          conditionVrai: '',
          conditionFaux: '',
          conditionNa: ''
        }
        obj.id_obj = this.id_produit++
        obj.id = data.questionHasFiles[i].question.id
        obj.colorFaux = data.questionHasFiles[i].question.colorFaux
        obj.colorNa =   data.questionHasFiles[i].question.colorNa
        obj.colorVrai = data.questionHasFiles[i].question.colorVrai
        obj.description = data.questionHasFiles[i].question.description
        obj.conditionVrai = data.questionHasFiles[i].conditionVrai
        obj.conditionFaux = data.questionHasFiles[i].conditionFaux
        obj.conditionNa =   data.questionHasFiles[i].conditionNa
        this.repProduit.push(obj)
      }
      for (let j = 0; j <  this.repProduit.length; j++) {
        this.ADD_ITEM_PRODUIT(this.produits_computed, this.repProduit[j])
      }
      this.activePrompt3 = true
    },
    editData (data) {
      this.intialise()
      this.Has_Med_Dos_Qty_update = true
      this.show_update = true
      this.id_produit =  ''
      this.produits_computed = []
      this.repProduit.length = 0
      this.id = data.id
      this.id_up = data.id
      this.name = data.name
      this.site = data.site.id
       
      for (let i = 0; i < data.questionHasFiles.length; i++) {
        const obj = {
          id: '',
          description: '',
          colorFaux: '',
          colorNa: '',
          colorVrai: '',
          conditionVrai: '',
          conditionFaux: '',
          conditionNa: ''
        }
        //obj.id = this.id_produit++
        obj.id = data.questionHasFiles[i].question.id
        obj.colorFaux = data.questionHasFiles[i].question.colorFaux
        obj.colorNa =   data.questionHasFiles[i].question.colorNa
        obj.colorVrai = data.questionHasFiles[i].question.colorVrai
        obj.description = data.questionHasFiles[i].question.description
        obj.conditionVrai = data.questionHasFiles[i].conditionVrai
        obj.conditionFaux = data.questionHasFiles[i].conditionFaux
        obj.conditionNa =   data.questionHasFiles[i].conditionNa
        this.repProduit.push(obj)
      }
      for (let j = 0; j <  this.repProduit.length; j++) {
        this.ADD_ITEM_PRODUIT(this.produits_computed, this.repProduit[j])
      }
      this.Check_update = true
      this.activePrompt2 = true
    },
   
    intialise () {
      this.id = ''
      this.id_up = ''
      this.name = ''
      this.site = ''
      this.sites = []
      this.conditionVrai = []
      this.conditionFaux = []
      this.conditionNa = []
      this.questions = []
      this.questions_list = []
      this.produits_computed = []
      this.validateFormation_intialise()
      this.show_update = false
      this.Check_update = false
      this.activePrompt1 = false
      this.activePrompt2 = false

    },
      
    deleteData (id) {
      this.$vs.dialog({
        type:'confirm',
        color: 'danger',
        title: 'Confirmation',
        text: 'Confirmer la suppression?',
        acceptText: 'Confirmer',
        cancelText: 'Annuler',
        accept: async () => {
          this.$vs.loading()
          this.$http.delete(`riskZoneAssessments/${id}/`)
            .then(response => {
              this.getAllriskZoneAssessments()
              window.getPrendTaCom.success('L\'évaluations de la zone de risque est supprimée avec succès.', response)
            })
            .catch(() => {
              window.getPrendTaCom.error({ message: 'La suppression a échoué.' })
            })
        }
      })
  
    },
    submitData () {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$vs.loading()
          if (this.Check_update === false) {
            const produits_store = this.questions_list
            for (let i = 0; i < produits_store.length; i++) {
              this.conditionVrai.push(produits_store[i].conditionVrai)
              this.conditionFaux.push(produits_store[i].conditionFaux)
              this.conditionNa.push(produits_store[i].conditionNa)
              this.questions.push(produits_store[i].id)          
            }
          } else if (this.Check_update === true) {

            const produits_store = this.produits_computed
            for (let i = 0; i < produits_store.length; i++) {
              this.conditionVrai.push(produits_store[i].conditionVrai)
              this.conditionFaux.push(produits_store[i].conditionFaux)
              this.conditionNa.push(produits_store[i].conditionNa)
              this.questions.push(produits_store[i].id)          
            }
          }
          if (this.Check_update === false) {
            this.input = {
              name : this.name,
              site : this.site,
              mission: 1,
              conditionVrai : this.conditionVrai,
              conditionFaux : this.conditionFaux,
              conditionNa : this.conditionNa,
              questions : this.questions
            }
          } else if (this.Check_update === true) {
            this.input = {
              id:this.id_up,
              name : this.name,
              site : this.site,
              mission: 1,
              conditionVrai : this.conditionVrai,
              conditionFaux : this.conditionFaux,
              conditionNa : this.conditionNa,
              questions : this.questions
            }
          }
          let url = 'riskZoneAssessments/'
          let methods = 'post'
          const message = {
            error: 'Votre enregistrement a échoué.',
            success: 'L\'évaluations de la zone de risque est enrégistrée avec succès.'
          }
          if (this.id_up) {
            url += `${this.id_up}/`
            methods = 'put'
            message.success = 'L\'évaluations de la zone de risque est modifiée avec succès.'
  
          }
  
          this.$http[methods](url, this.input)
            .then((response) => {
              window.riskZoneAssessments.getAllriskZoneAssessments()
              window.getPrendTaCom.success(message.success, response)
              this.intialise()
              this.validateFormation_intialise()
            })
            .catch((error) => {
              const clefs = Object.keys(error.response.data)
              for (let i = 0; i < clefs.length; i++) {
                const item = clefs[i]
                if (item === 'name') {
                  this.$vs.notify({
                    time: 4000,
                    title: 'ATTENTION',
                    text: 'Champ libelle obligatoire',
                    color: 'warning',
                    position: 'top-center'
                  })
                } else if (item === 'site') {
                  this.$vs.notify({
                    time: 4000,
                    title: 'ATTENTION',
                    text: 'Champ site est obligatoire',
                    color: 'warning',
                    position: 'top-center'
                  })
                } else if (item === 'sites') {
                  this.$vs.notify({
                    time: 4000,
                    title: 'ATTENTION',
                    text: 'Champ sites est obligatoire',
                    color: 'warning',
                    position: 'top-center'
                  })
                } else if (item === 'conditions') {
                  this.$vs.notify({
                    time: 4000,
                    title: 'ATTENTION',
                    text: 'Champ condition est obligatoire',
                    color: 'warning',
                    position: 'top-center'
                  })
                } else if (item === 'questions') {
                  this.$vs.notify({
                    time: 4000,
                    title: 'ATTENTION',
                    text: 'Champ question est obligatoire',
                    color: 'warning',
                    position: 'top-center'
                  })
                }
              }
              this.$vs.notify({
                time: 8000,
                title: 'ENREGISTREMENT',
                text: error.response.data.error,
                color: 'danger',
                position: 'top-right'
              })
              this.$vs.loading.close()
            })
        } 
      })
        
    },
    getAllsites () {
      this.$vs.loading()
      this.$http.get('sites/')
        .then((response) => {
          this.sites_list = response.data
          this.$vs.loading.close()
        })
        .catch(() => {
          this.$vs.loading.close()
        })
    },
    getAllquestions () {
      this.$vs.loading()
      this.$http.get('questions/')
        .then((response) => {
          this.questions_list = response.data
          this.$vs.loading.close()
        })
        .catch(() => {
          this.$vs.loading.close()
        })
    },
    getAllriskZoneAssessments () {
      this.$vs.loading()
      this.$http.get('riskZoneAssessments/')
        .then((response) => {
          this.riskZoneAssessments = response.data
          this.$vs.loading.close()
        })
        .catch(() => {
          this.$vs.loading.close()
        })
    }
 
  },
  created () {
    window.riskZoneAssessments = this
    this.getAllsites()
    this.getAllquestions()
    this.getAllriskZoneAssessments()
  },
  mounted () {
    this.isMounted = true
  }
}
</script>
  
  <style lang="scss">
  #data-list-list-view {
    .vs-con-table {
  
      /*
        Below media-queries is fix for responsiveness of action buttons
        Note: If you change action buttons or layout of this page, Please remove below style
      */
      @media (max-width: 689px) {
        .vs-table--search {
          margin-left: 0;
          max-width: unset;
          width: 100%;
  
          .vs-table--search-input {
            width: 100%;
          }
        }
      }
  
      @media (max-width: 461px) {
        .items-per-page-handler {
          display: none;
        }
      }
  
      @media (max-width: 341px) {
        .data-list-btn-container {
          width: 100%;
  
          .dd-actions,
          .btn-add-new {
            width: 100%;
            margin-right: 0 !important;
          }
        }
      }
  
      .product-name {
        max-width: 23rem;
      }
  
      .vs-table--header {
        display: flex;
        flex-wrap: wrap;
        margin-left: 1.5rem;
        margin-right: 1.5rem;
        > span {
          display: flex;
          flex-grow: 1;
        }
  
        .vs-table--search{
          padding-top: 0;
  
          .vs-table--search-input {
            padding: 0.9rem 2.5rem;
            font-size: 1rem;
  
            &+i {
              left: 1rem;
            }
  
            &:focus+i {
              left: 1rem;
            }
          }
        }
      }
  
      .vs-table {
        border-collapse: separate;
        border-spacing: 0 1.3rem;
        padding: 0 1rem;
  
        tr{
          box-shadow: 0 4px 20px 0 rgba(0,0,0,.05);
          td{
            padding: 20px;
            &:first-child{
              border-top-left-radius: .5rem;
              border-bottom-left-radius: .5rem;
            }
            &:last-child{
              border-top-right-radius: .5rem;
              border-bottom-right-radius: .5rem;
            }
          }
          td.td-check{
            padding: 20px !important;
          }
        }
      }
  
      .vs-table--thead{
        th {
          padding-top: 0;
          padding-bottom: 0;
  
          .vs-table-text{
            text-transform: uppercase;
            font-weight: 600;
          }
        }
        th.td-check{
          padding: 0 15px !important;
        }
        tr{
          background: none;
          box-shadow: none;
        }
      }
  
      .vs-table--pagination {
        justify-content: center;
      }
    }
  }
  </style>
  <style scoped>
  
  </style>
  